import config from '../../../config/countries';
import axios from 'axios';

async function getCoursesWithAllFields(currentCountry, ids) {
  let response = [];
  let appConfig = config(currentCountry);
  if (!ids.length) {
    return [];
  }
  if (appConfig.current.code === 'br') {
    try {
      response = await axios.get(appConfig.br.showcaseApiUrl, {
        params: { ids: ids },
      });
    } catch (e) {
      console.error(e);
    }
  }
  if (appConfig.current.code === 'mx') {
    try {
      response = await axios.get(appConfig.mx.showcaseApiUrl, {
        params: { ids: ids },
      });
    } catch (e) {
      console.error(e);
    }
  }
  return response.data.data.data;
}

async function getAdditionalCoursesFromBasket(basket) {
  let additionalCoursesIds = [];
  basket.forEach((basketItem) => {
    if (
      basketItem.additionalNomenclatures &&
      basketItem.additionalNomenclatures.length
    ) {
      basketItem.additionalNomenclatures.forEach((item) =>
        additionalCoursesIds.push(item.id),
      );
    }
  });
  return [...new Set(additionalCoursesIds)];
}

function getProductPrice(product, vue) {
  const defaultSalePrice = vue.$store.state.sale.sale.defaultSaleType;
  // const basePrice = Number(product?.rdCrmProduct?.basePrice || 0);
  const basePrice = Number(product?.basePrice || 0);
  let price = {
    priceInstallmentPlan: 12,
    basePrice,
    basePriceFormatted: vue.$n(basePrice, 'currency'),
  };
  let productPrice;
  let priceType;

  if (product.salePrice.isDefaultPrice) {
    // если цена не выбрана
    const source = product.sources.find(
      (source) => source.pageType === defaultSalePrice,
    );
    if (source) {
      // productPrice = product.rdCrmProductPrices.find(productPrice => productPrice.priceTypeId === source.priceTypeId);
      productPrice = product.nomenclaturePrices.find(
        (productPrice) => productPrice.priceTypeId === source.priceTypeId,
      );
      if (productPrice) {
        price.price = productPrice?.price;
        priceType = product.priceTypes.find(
          (priceType) => priceType.id === productPrice.priceTypeId,
        );
        price.percent = priceType.percent;
        price.priceInstallmentPlanPrice = vue.$app.getCurrency(
          price.price / price.priceInstallmentPlan,
        );
      }
    }
    // } else if (product.salePrice.withRdCrmProductPrice) { // если цена выбрана
  } else if (product.salePrice.withNomenclaturePrice) {
    // если цена выбрана
    // productPrice = product.rdCrmProductPrices[0];
    productPrice = product.nomenclaturePrices[0];
    if (productPrice) {
      price.price = productPrice.price;
      priceType = product.priceTypes.find(
        (priceType) => priceType.id === productPrice.priceTypeId,
      );
      price.percent = priceType.percent;
      price.priceInstallmentPlanPrice = vue.$app.getCurrency(
        price.price / price.priceInstallmentPlan,
      );
    }
  }

  return price;
}

export {
  getCoursesWithAllFields,
  getAdditionalCoursesFromBasket,
  getProductPrice,
};
