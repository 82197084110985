//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RadioCheckGroup from '../d0822/fields/radioCheckGroup.vue';
import Slider from '../d0822/fields/slider.vue';
import filterMixin from '@/mixins/filter';

export default {
  components: {
    RadioCheckGroup,
    Slider,
  },
  mixins: [filterMixin],
  props: {
    namespace: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      vantagensOptions: [],
    };
  },
  computed: {
    isBr() {
      return this.$nuxt.$config.COUNTRY === 'br';
    },
    jobGuarantee: {
      get() {
        return this.filter.jobGuarantee;
      },
      set(payload) {
        return this.filterFieldUpdate('jobGuarantee', payload);
      },
    },
    free: {
      get() {
        return this.filter.free;
      },
      set(payload) {
        return this.filterFieldUpdate('free', payload);
      },
    },
    types: {
      get() {
        return this.filter.types;
      },
      set(payload) {
        return this.filterFieldUpdate('types', payload);
      },
    },
    duration: {
      get() {
        return { min: +this.filter.durationMin, max: +this.filter.durationMax };
      },
      set(payload) {
        const min = payload?.min;
        const max = payload?.max;

        if (min !== +this.filter.durationMin) {
          this.filterFieldUpdate('durationMin', +min);
        }

        if (max !== +this.filter.durationMax) {
          this.filterFieldUpdate('durationMax', +max);
        }
      },
    },
    topic: {
      get() {
        return this.filter.topic;
      },
      set(payload) {
        this.filterFieldUpdate('topic', payload);
      },
    },
  },
  methods: {
    fieldExists(name) {
      return this.filterFields?.find((i) => i.name === name);
    },
  },
};
