//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'lodash';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    fieldType: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 24,
    },
  },

  data() {
    return {
      model: '',
    };
  },
  watch: {
    value: {
      handler(payload) {
        this.model = payload;
      },
      immediate: true,
    },
  },
  methods: {
    debounceInput: debounce(function () {
      let value = '';
      if (this.model) value = this.model.trim();
      this.$emit('input', value);
    }, 250),
  },
};
