//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    price: {
      type: Object,
      default: () => ({}),
    },
  },
};
